import React, { memo, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Section from './Section';
import SectionParagraph from './SectionParagraph';
import { AiFillLinkedin, AiFillMail, AiFillPhone } from 'react-icons/ai';
import { useImages } from '../hooks';
import * as css from './WhoWeAre.module.css';

const imagesQuery = graphql`
  query {
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "team" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

const WhoWeAre = () => {
  const data = useStaticQuery(imagesQuery);
  const images = useImages(data.images.nodes);

  return (
    <Section observe="who-we-are" className={css.root}>
      <SectionParagraph title="Who we are">
        <p>Round.</p>
      </SectionParagraph>
      <div className={css.graphic}>
        <div className={css.text}>
          <p>/raʊnd/ Shaped like a circle.</p>
          <p>The circle is the symbol of Round. The symbol of us.</p>
          <p>
            It signifies our rounded approach to consulting; the notion that
            true problem-solving involves both a business perspective and a
            human perspective; that top-down and inside-out thinking is mandated
            only by bottom-up and outside-in understanding.
          </p>
          <p>
            It stands for wholeness; the preference for co-creation and
            inclusion over predisposition and exclusion; the choice of a
            customized approach over standardized frameworks.
          </p>
          <p>
            It is how we embrace people with different backgrounds and beliefs
            and make them part of us for a sustainable life, no matter their
            life stage.
          </p>
          <p>
            It is our recognition that everything is circular; what goes around,
            comes around, eventually; by investing in sustainable impact, we
            choose to think “hopefully”.
          </p>
          <p>
            The circle is perpetual movement, like the planets' journey around
            the sun and the great rhythm of the universe. It is a movement with
            no end and no beginning; something that will never grind to a halt,
            never cease to evolve.
          </p>
          <p>Just like us.</p>
          <p>This is why we are Round.</p>
        </div>
        <Person
          name="Amilla"
          image={images.amilla}
          phone="+45 40 23 26 00"
          email="amilla.wurtz@round.dk"
          title="Consultant"
          url="https://dk.linkedin.com/in/amillaw%C3%BCrtz/"
        >
          <p>
            I have 2 years of consulting experience from the agency world, where
            I've had the privilege of working closely with a diverse set of
            clients. My work has ranged from crafting go-to-market strategies
            and value propositions to diving deep into market and customer
            insights. The industries I've touched upon are equally varied,
            including renewable energy, tech, fin-tech, and law. Additionally, I
            have over two years of experience at GreenMobility, a Danish
            car-sharing company, where I focused on strategizing and expanding
            new business operations throughout Europe.
          </p>
          <p>
            On the academic front, I hold a M.Sc. in International Business and
            a B.Sc. in Economics and Business Administration, both from
            Copenhagen Business School.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I'm part of
            building Round for a variety of reasons, all of which converge on a
            single mission: to transform the consulting sector by centering it
            around human values. I'm thrilled to contribute to a company that
            not only leads in its domain but also serves as an agent for
            substantial societal change, extending beyond the industry to affect
            the wider community. Additionally, the team is a significant
            motivator for me. Working alongside a diverse set of experts, who
            are not just proficient in their specializations but also genuinely
            dedicated to enacting positive change, aligns with my own
            aspirations for constant learning, both personally and
            professionally.
          </p>
        </Person>
        <Person
          image={images.andreas}
          name="Andreas"
          email="andreas.wester@round.dk"
          phone="+45 53 53 43 95"
          title="Partner & co-founder"
          url="https://www.linkedin.com/in/andreaswester/"
        >
          <p>
            I spent the last decade in strategy consulting as a partner with ReD
            Associates in New York and with BCG and QVARTZ. I have also worked
            at the Danish Ministry of Finance, in research and as a journalist.
            I co-founded a few companies and an NGO, none of which ever really
            took off in a big way but all of which gave me a keen interest in
            starting a company with great people.
          </p>
          <p>
            I get my professional kicks from teaming up with senior executives
            and their organizations to help solve their most difficult and
            meaningful problems from a combined human and commercial
            perspective. Examples include corporate strategy, customer loyalty
            programs, transformation, and cultural change. I am passionate about
            combining liberal arts and the humanities with traditional strategy
            consulting. I write and give speeches and I am a member of the
            Tech.dk-committee formed by Danish union DJOEF.
          </p>
          <p>
            I somewhat nerdily collect academic degrees. I have an MBA from
            London Business School, an MA in Cultural Studies from Goldsmith’s
            College, London, an MSc and a BA in Political Science from
            University of Copenhagen and a BA Honors in Politics from University
            of Cape Town. And still counting
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I want to join
            forces with cool people and clients to change the world for the
            better while being a great father, an active participant in my
            community and have time to look at art on the weekends, binge
            Netflix on weekdays, read books and try out all the new sports that
            I am eager to start learning but never patient enough to truly
            master.
          </p>
        </Person>
        <Person
          name="Anne Sofie"
          image={images.anne_sofie}
          phone="+45 21 74 45 98"
          email="anne.sofie.lind@round.dk"
          title="Manager"
          url="https://www.linkedin.com/in/annesofielind/"
        >
          <p>
            I have +3 years of consulting experience from Vertical Strategy (now
            Bain & Company), where I have been working in close collaboration
            with clients on strategy development and business model innovation
            across many different industries. In addition, I have worked at
            Ørsted with strategy execution within Offshore Wind, and I have been
            leading business development in a Danish tech startup (sold in
            September 2022).
          </p>
          <p>
            I hold a M.Sc. in International Business from Copenhagen Business
            School and a B.Sc. In Economics and Business. I also hold a CEMS
            Master in International Management from Vienna University of
            Economics and Business.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I have joined
            Round because of the people and the strong set of values that guides
            everything we do. I want to be part of building a consulting and
            investment firm with a human-first approach, because I believe it
            will have an immensely positive impact on people in Round and the
            clients that we join forces with to solve the most challenging and
            meaningful problems for businesses and society.
          </p>
        </Person>
        <Person
          image={images.bertram}
          name="Bertram"
          email="bertram.thoning@round.dk"
          phone="+45 42 95 01 78"
          title="Consultant"
        >
          <p>
            I am currently pursuing a Master’s in Economics at The University of Copenhagen. Before I joined Round, I worked with start-ups in Copenhagen, primarily within the fields of financial accounting and service management.
          </p>
          <p>
            I am passionate about helping clients solve strategic problems in a creative and purpose-driven way, combining data-driven results with economics to create perspective and insights that secure holistic solutions. I am also passionate about working alongside inspiring people.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I want to be part of building a consultancy that focuses on sustainable solutions and a people-first agenda, helping clients solve business problems in an impactful way while changing the world for the better.
          </p>
        </Person>
        <Person
          image={images.cecilie}
          name="Cecilie"
          email="cecilie.eldrup@round.dk"
          phone="+45 20 89 28 04"
          title="Consultant"
          url="https://www.linkedin.com/in/cecilieeldrupjoergensen/"
        >
          <p>
            I have +3 years of consulting experience from TrackMan, a leading
            sport and tech company. As an internal consultant, I closely
            collaborated with top management, gaining exposure to a diverse
            range of strategic projects. These included developing corporate and
            business unit strategies, go-to-market strategies, and optimizing
            recurring revenue streams.
          </p>
          <p>
            In addition to this I hold a BSc in Economics and Business
            Administration (HA-Almen) from Copenhagen Business School.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I joined Round for
            multiple reasons, but most importantly, it stems from my belief in
            the strong purpose and people-centric approach embraced by Round. I
            could sense a strong passion and pride filling the room when we
            talked about Round's prior engagements, and I share the same values
            and beliefs. With a unique combination of startup entrepreneurial
            spirit, purpose-driven mindsets, and a genuine commitment to
            investing in both their people and society, Round provides me with
            the perfect platform to channel my enthusiasm and unlock my true
            potential.
          </p>
        </Person>
        <Person
          name="Céleste"
          image={images.celeste}
          email="celeste.kigali@round.dk"
          phone="+45 61 40 44 36"
          title="Research Consultant"
          url="https://dk.linkedin.com/in/c%C3%A9lestemichelkigalinshimiyimana"
        >
          <p>
            I have spent the last decade, academically and professionally,
            sharpening my foremost expertise; To understand how people around
            the world live and think about their lives. From CEOs to smallholder
            farmers, jihadists to feminist activists, and everything in between,
            I have had the pleasure of trying to understand humans in their
            context.
          </p>
          <p>
            I have ~3 years of ethnographic experience in Baghdad, New York,
            Beirut, Paris, Copenhagen and Stockholm with a primarily aesthetic
            end-goal funded by Statens Kunstfond, Dansk Journalist Forbund and
            DFI amongst others. Furthermore, I have ~1,5 years of academic field
            studies in Accra, Nairobi, Maputo and the USVI looking into tech
            startups and BoP Economics. Lastly, I have had years of pleasure
            collaborating with some of the leading society 3.0 Danish artists
            and debaters on award winning and critically acclaimed projects.
          </p>
          <p>
            I hold a BA from Roskilde University in Cultural Encounters &
            Communication and an MA from the University of Copenhagen in African
            Studies.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I am part of
            building Round because I perceptibly believe in our Holistic
            Strategic Problem Solving model(s) where we combine the best tools
            from the quantitative business sciences with the best tools from the
            qualitative human sciences.
            <p>
              This is important because plentiful sociologists and
              anthropologists argue that we are currently entering a new epoch
              of how to live in the West. Communities are radically evolving and
              generally companies who only apply deductive models in unknown
              cultural settings tend to fail in their attempts.
            </p>
            <p>
              Therefore, I am thrilled to be part of a team of field specialists
              who are all curious to learn from each and do impactful
              interdisciplinary work.
            </p>
          </p>
        </Person>
        <Person
          name="Cristina"
          image={images.cristina}
          email="cristina.j.rabassa@round.dk"
          phone="+45 53 55 99 59"
          title="Consultant"
          url="https://www.linkedin.com/in/cristinajimenezrabassa/"
        >
          <p>
            I have over two years of consulting experience at Round, and I have previously been working with international trade and investment projects within a public government agency as well as social innovation. I am passionate about using the power of data and new methods in business to uncover new perspectives and achieve a social and sustainable impact.
          </p>
          <p>
            I hold a Master’s degree in Social Data Science from the University of Copenhagen and a Bachelor’s degree in International Business and Economics from University Pompeu Fabra in Barcelona.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I joined Round because I was inspired to be part of building a company that puts purpose and people at the top of everything, striving for a sustainable business model that leads the way for others. Additionally, Round works towards solving problems holistically and having a multidisciplinary team, which offers the great opportunity to use my learnings from social data science and business and be part of innovating the ways in which we do consulting.
          </p>
        </Person>
        <Person
          name="Emilie"
          image={images.emilie}
          phone="+45 93 40 60 31"
          email="emilie.heide@round.dk"
          title="Manager"
          url="https://www.linkedin.com/in/emilie-heide-589526142/"
        >
          <p>
            Throughout my career, I've had the privilege of working across various sectors and countries – providing strategic advice to Fortune 500 companies and NGOs as well as revamping sustainability strategies for leading retail chains and BioTech companies. I completed my MSc in Political Science from the University of Copenhagen and Sciences PO and spent +3 years at ReD Associates, refining my skills in strategic consultancy. All of my experiences have fueled my commitment to innovative problem-solving and a human-centered approach.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I'm motivated to join a company that has the ambition to do things differently – for our clients and for its employees. And Round seeks to do both, offering a holistic approach to problem-solving that draws on the classic consultant toolbox while daring to look beyond the Excel sheets and include the human perspective. 
          </p>
        </Person>
        <Person
          name="Emma"
          image={images.emma}
          phone="+45 71 92 30 73"
          email="emma.revilliod@round.dk"
          title="Consultant"
          url="https://www.linkedin.com/in/emma-revilliod-5a397b135/"
        >
          <p>
            Born and raised in France, I studied in Canada where I graduated from a Bachelor in Business Administration at HEC Montreal. During my Bachelor I specialized in Applied Economics, and I completed my business degree with focus on Political Sciences at McGill University. I had the opportunity to use my background in economy and politics while working for non-profit organizations in Montreal. I was responsible for developing and fostering public and private partnerships.
          </p>
          <p>
            I then completed my MSc in Business and Development Studies at Copenhagen Business School, and became truly passionate about understanding and resolving modern challenges with a human approach. Studying development allowed me to explore new perspectives and I believe that diversity brings people together and forward, towards a better, more sustainable future.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> As a human-first and purpose-driven company, Round is challenging the conventional idea of consultancy and I wish to take part in this adventure. I share Round's purpose to develop innovative business models focusing on creating authentic connections and making sustainable impact. Being a part of Round represents an opportunity for me to participate in setting the agenda for tomorrow while learning from great humans.
          </p>
        </Person>
        <Person
          image={images.flemming}
          name="Flemming"
          email="flemming.pedersen@round.dk"
          phone="+45 25 47 29 28"
          title="Partner & co-founder"
          url="https://www.linkedin.com/in/flemmingpedersen/"
        >
          <p>
            I have +13 years of consulting experience from QVARTZ, Bain &
            Company and BCG. In addition, I have 10 years of experience working
            with the Danish Ministry of Finance and the Ministry of Economics
            and Business Affairs. My main passion and expertise is
            purpose-driven strategy development, sustainability and social
            impact, stakeholder management and value creation trough more
            sustainable operating models. My client experiences include
            progressive industry leaders in the private sector, as-well as
            business owning foundations and non-profit organizations.
            Furthermore, I have extensive experience working with the public
            sector.
          </p>
          <p>
            I hold a Master’s in Political Science from Aarhus University and a
            PLD from Harvard Business School. As a part of my continuing
            Executive Education, I have most recently studied “Sustainable
            Business Strategy” at Harvard Business School.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I am super
            motivated by people driven impact, and the purpose of Round is to
            work with and invest in people and businesses, who want to change
            the word for the better – while we prove that it is possible to both
            be a top tier consultant AND have a sustainable life.
          </p>
        </Person>
        <Person
          image={images.frederik}
          name="Frederik"
          email="frederik.hojland@round.dk"
          phone="+45 29 69 69 35"
          title="Manager"
          url="https://www.linkedin.com/in/frederik-h%C3%B8jland-b999a764/"
        >
          <p>
            I have five years of consulting experience from QVARTZ and Bain &
            Company with primary industry focus on consumer products, tech/SaaS,
            manufacturing, and social impact, working in close collaboration
            with clients on critical topics, incl. corporate and business unit
            strategy, operating model, value management/commercial excellence,
            integration and more.
          </p>
          <p>
            I am very passionate about people, and I strongly believe that
            connecting on a deeper level with colleagues and clients is
            essential to unleashing the full potential of the individual and the
            team.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I joined Round
            after six months of travelling with my girlfriend, having spent
            countless of hours thinking and fantasizing about my future
            workplace, and I did so for three reasons: First, because Round’s
            values and modern approach to building a workplace and collaborating
            with clients, which is rooted in a strong emphasis on sustainability
            and DEI, align with my values and beliefs. Second, I wanted to be
            part of building a company – a place that is top-tier in its field
            without compromising its human-first values. Third, the fact that
            Round is committed to give back to society by investing time and
            money in social impact ventures.
          </p>
        </Person>
        <Person
          image={images.joakim}
          name="Joakim"
          email="joakim.viggers@round.dk"
          phone="+45 60 18 05 94"
          title="Senior Consultant"
          url="https://www.linkedin.com/in/joakim-viggers-b75315b2/"
        >
          <p>
           With several years of experience in strategic roles, including positions as Manager and Senior Consultant at Valcon, as well as experience as a Graduate and Strategic Business Developer at PFA, I have developed a deep understanding of strategy, commercial excellence, and operational models.
          </p>
          <p>
          My educational background includes an M.Sc. in Strategic Management from the Rotterdam School of Management, and a Cand.Merc in International Management and Marketing from CBS – all of which, has given me a solid foundation in strategic management and international business.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I have followed Round from the start and quickly recognized that they are not only highly skilled management consultants but also genuinely great people striving to innovate and enhance problem-solving practices for everyone involved. Additionally, I see joining Round as a fantastic opportunity to contribute to a growing company with inspiring values and ambitions. 
          </p>
        </Person>
        <Person
          image={images.jonathan}
          name="Jonathan"
          email="Jonathan.kjeldgaard@round.dk"
          phone="+45 23 99 19 34"
          title="Junior Consultant"
          url="https://www.linkedin.com/in/jonathan-kjeldgaard-b7607a149/"
        >
          <p>
           My career journey includes experience at Bain & Company as an Intern and Junior Associate Consultant, as well as internal business development at Playable, a Gamification SaaS startup.
          </p>
          <p>
          I hold a Bachelor's degree in Economics and Business Administration from Aarhus BSS, and I am currently pursuing a Master's in Business and Development Studies from CBS. My background reflects my passion for exploring and learning from innovative approaches to business in the developing world.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I joined Round to be part of building a consultancy where sustainable impact is highly prioritized. Furthermore, I resonate deeply with Round’s human-first philosophy, which gives employees more balance and flexibility, and plays a key role in Round's holistic approach to problem-solving. This provides an ideal setting to grow both professionally and personally while making a real impact for businesses and society.
          </p>
        </Person>
        <Person
          image={images.kai}
          name="Kai"
          email="kai.zhang@round.dk"
          phone="+45 93 40 96 76"
          title="Consultant"
          url="https://www.linkedin.com/in/kai-zhang-6303b1157/"
        >
          <p>
           With a background in Mathematics and Economics from the University of Copenhagen, and an MSc in Advanced Economics and Finance from CBS, I've developed a solid foundation in quantitative methods and data analysis. My professional journey has taken me through various roles in both the financial sector and risk management, including positions at Danmark’s National bank and McKinsey. Outside of work, I'm passionate about engaging in formal debate, alternative economic theories and expanding my intellectual curiosity.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> Becoming a part of Round means embracing my whole self and combining all the quirky interests I have into a well-ROUNDed life. Round’s human-first approach and desire to include democratic ownership resonate strongly with my personal principles and priorities. From the first conversation, I felt a strong alignment between my personal desires and Round's values. I am excited to develop alongside Round and explore how my personal interests can enrich my professional life.
          </p>
        </Person>
        <Person
          image={images.malthe}
          name="Malthe"
          email="malthe.andersen@round.dk"
          phone="+45 28 68 14 05"
          title="Junior Consultant"
          url="https://www.linkedin.com/in/malthe-bell-andersen-494170138/"
        >
          <p>
            I enjoy working with complex issues that mandate not only a business
            perspective but also an insight on societal impact. Problem-solving
            is where I find my drive, especially when I am able to provide
            creative solutions that originate from thinking outside the box.
          </p>
          <p>
            I am currently studying for my BSc in Political Science at
            Copenhagen University.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> Round offers a
            unique opportunity in two ways. Firstly, being part of a growing
            company that is still being shaped provides a dynamic and exciting
            experience. Secondly, I believe that incorporating sustainability
            into the DNA of a business is the future of the private sector. I
            also think that the impact the private sector has on society will
            only increase. Therefore, I believe that the quintessential goal for
            companies is to see sustainability as a condition for commercial
            excellence and not merely a bonus.
          </p>
        </Person>
        <Person
          image={images.bina}
          name="Jacobina"
          email="jacobina.hvidt@round.dk"
          phone="+45 93 10 99 91"
          title="Marketing Manager"
          url="https://www.linkedin.com/in/malthe-bell-andersen-494170138/"
        >
          <p>
            I have +3 years of experience in marketing departments at Canon EMEA and QVARTZ, where I worked with various forms of copy, digital design and branding. I love utilizing my creative mindset and transforming ideas into engaging and inspiring content. I have a BA in English and an MA in English and Communication from The University of Copenhagen. The latter of which I completed during my exchange in Thailand, where I wrote my thesis on sustainable marketing. 
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I had been following Round’s journey on the sidelines and found myself continuously inspired by their goals and integrity. Their human-first approach and focus on sustainability are values that I strongly believe in, and I am thrilled to be in a company that is taking active steps towards positively impacting our society. 
          </p>
        </Person>
        <Person
          image={images.maria}
          name="Maria"
          email="maria.wahl@round.dk"
          phone="+45 30 14 24 13"
          title="Head of People Growth"
          url="https://www.linkedin.com/in/maria-wahl-0927177/"
        >
          <p>
            My career has been focused on the consulting industry for the last +6 years with Qvartz and Bain & Company, which has provided me with valuable insights into shaping staffing strategies and fostering professional development. These experiences have allowed me to refine my skills even further in people development and coaching and instilled a deep appreciation for diverse perspectives and a commitment to creating positive social impact. 
          </p>
          <p>
            My academic journey includes an M.Sc. in English and Intercultural Market Studies, alongside a B.Sc. in English and International Marketing, both from Copenhagen Business School.  
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I joined because of the people and what they are creating – a human-first consulting firm. I truly believe in Round’s purpose to create socially sustainable impact – by focusing on human relations, meeting the needs of the present without compromising the future with an aim to improve matters significantly. All the while making sure the work and the culture is human-first. Furthermore, I don’t just follow the guidelines here – I get to be a part of creating them and develop them even further with amazing people.
          </p>
        </Person>
        <Person
          image={images.martin}
          name="Martin"
          email="martin.j.mortensen@round.dk"
          phone="+45 29 68 69 40"
          title="Partner & co-founder"
          url="https://www.linkedin.com/in/martinjaquetmortensen/"
        >
          <p>
            I have +8 years of consulting experience from QVARTZ and Bain &
            Company. Before consulting, I also worked in the public sector. I’m
            grounded in corporate / sector strategy and strategy execution, M&A
            / commercial due diligence (sell- and buy-side) and operating model
            transformation with additional experience in e.g. commercial
            excellence and efficiency analysis. I’ve worked with both Public and
            private sector clients (primarily Nordic mid-cap) across industries
            such as PE, energy, financial services, and transportation.
          </p>
          <p>
            I hold a M.Sc. in Political Science from Aarhus University and
            completed part of it at the Danish Embassy to the United Nations in
            New York.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I want to work
            loyal to the premise that value creation and sustainable change are
            only realized when stellar analysis goes hand in hand with a
            people-first agenda – for both our clients, ourselves, and the
            society at large.
          </p>
        </Person>
        <Person
          image={images.morten}
          name="Morten"
          email="morten.andreassen@round.dk"
          phone="+45 29 68 69 49"
          title="Partner & co-founder"
          url="https://www.linkedin.com/in/mortenandreassen/"
        >
          <p>
            I have +8 years of consulting experience from QVARTZ and Bain &
            Company. As part of my time in QVARTZ, I did 6 months of exchange at
            Port Jackson Partners, a Sydney based tier 1 consulting company. In
            addition, I have 2 years of M&A experience from Clearwater
            International.I am grounded in corporate strategy, people, and
            organization with additional experience in e.g., commercial due
            diligence and operating model transformation. I have global
            experience from working in 25+ countries and industry experience
            from market leading companies within FMCG, energy and manufacturing.
          </p>
          <p>
            I hold a Master’s in finance from Aarhus University and completed
            part of it at the London School of Economics.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I want to build a
            consulting company with strong people-oriented values and a focus on
            creating a positive impact on our planet – and create a place where
            we dare to challenge and redefine strategic thinking and how we work
            with our clients.
          </p>
        </Person>
        <Person
          name="Oliver"
          image={images.oliver}
          phone="+45 31 18 41 48"
          email="oliver.langhoff@round.dk"
          title="Senior Consultant"
          url="https://www.linkedin.com/in/oliver-quorning-langhoff/"
        >
          <p>
            I have close to 2 years of experience from Investment Banking at ABG
            Sundal Collier, helping clients across different industries drive
            forward their strategic agenda through M&A transactions and IPOs.
            Prior to this, I spent 1+ year managing illiquid investments across
            equity, debt, and infrastructure projects for Danske Bank and Danica
            Pension.
          </p>
          <p>
            From my prior experiences I have found that what really excites me
            is working with clients towards finding solutions to challenges that
            are central to the business. I enjoy being in an environment that
            encourages experimentation and out-of-the-box thinking while working
            on business-critical issues in close collaboration with clients and
            colleagues.
          </p>
          <p>
            I hold a MSc. in Finance and Investments from Copenhagen Business
            School and I have done an exchange semester at the University of
            Michigan.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I have joined
            Round, first and foremost, because of the people. I am highly
            motivated by working alongside people from different backgrounds and
            I want to be challenged in my own way of thinking. I believe that
            there is a great potential to deliver sustainable solutions to
            clients by driving meaningful collaboration through a people-first
            culture.
          </p>
        </Person>
        <Person
          image={images.pia}
          name="Pia"
          email="pia.moller@round.dk"
          phone="+45 51 29 33 79"
          title="Partner & Head of People Support"
          url="https://www.linkedin.com/in/pia-lindhage-m%C3%B8ller-2b7a212/"
        >
          <p>
            I spent the last almost 16 years in consulting as team leader in
            QVARTZ and Bain & Company supporting my colleagues on a daily basis.
            In addition, I have 10+ years of experience working in the travel
            industry as a travel consultant.
          </p>
          <p>
            I am passionate about making the wheels turn smoothly, but I get my
            energy from the people I work closely with, from helping and
            supporting them. I am extremely interested in how we grow and
            develop as a team and equally eager to take part in this
            development/growth.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I want to be part
            of something meaningful and to build a company driven by a
            people-first culture. I believe that being part of Round will give
            me the best starting point to give my best - every day! And also, I
            want to be part of a company that focuses on sustainability - not
            only for the people in Round, but also for the society.
          </p>
        </Person>
        <Person
          image={images.rasmusg}
          name="Rasmus G."
          email="rasmus.gravergaard@round.dk"
          phone="+45 25 54 02 52"
          title="Junior Consultant"
          url="https://www.linkedin.com/in/rasmus-gravergaard-737027221/"
        >
          <p>
            I previously worked at Lundbeckfonden, where I focused primarily on projects centered around sustainability and strategy. I am also a member of the CBS International Case Teams and have represented CBS internationally at several Case Competitions. 
          </p>
          <p>
          I am currently pursuing a B.Sc. in International Business at CBS and have recently returned from an exchange semester at UC Berkeley.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> As a business student, strategy consulting has always interested me due to its demand for responsibility coupled with the potential for a great and lasting impact. Round instantly appealed to me as a space where strategies are crafted with a positive impact in mind. Moreover, as a student, the smaller size of Round stood out to me, giving me the opportunity to collaborate closely with highly experienced professionals allowing for a steep learning curve.
          </p>
        </Person>
        <Person
          image={images.rasmus}
          name="Rasmus S."
          email="rasmus.sorensen@round.dk"
          phone="+45 24 29 39 39"
          title="Partner & co-founder"
          url="https://www.linkedin.com/in/rasmussoerensen/"
        >
          <p>
            I have had the pleasure of spending +8 years in consulting,
            primarily with QVARTZ and subsequently with Bain & Company. Prior to
            consulting, I did an 11-year stint in advertising with leading
            advertising agencies like Wibroe, Duckert & Partners/PeopleGroup,
            Grey, and DDB. I am truly passionate about working in the nexus
            between strategy, company culture, and value creation – to fuel
            transformation and strengthen market positioning. My experience
            include work for market leaders across the Nordics within
            manufacturing, transportation, retail, and energy.
          </p>
          <p>
            I hold a Master’s in International Management & Marketing from
            Copenhagen Business School and an MBA semester from York University
            – Schulich School of Business, Canada.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I want to build a
            people-first, culture-driven consulting outfit, where experienced
            consultants and shooting stars join forces to create sustainable
            strategies for clients with progressive agendas.
          </p>
        </Person>
        <Person
          image={images.roar}
          name="Roar"
          email="roar.brun.jensen@round.dk"
          phone=""
          title="Senior Advisor"
          url="https://www.linkedin.com/in/rasmussoerensen/"
        >
          <p>
            I have worked as a strategy consultant for ∼30 years, including almost 20 as Partner in QVARTZ and Bain&Company. My main focus has been on strategy development, organizational design, mergers & acquisitions, where I have advised on ∼250 transaction assignments.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> To have fun and use my skills to help good people.
          </p>
        </Person>
	<Person
          image={images.sarah}
          name="Sarah"
          email="sarah.hamilton@round.dk"
          phone="+45 31 72 62 10"
          title="Consultant"
          url="https://www.linkedin.com/in/sarahhamiltonjakobsen/"
        >
          <p>
            I bring 2 years of consulting experience with Strategy& and an additional
            year in the FMCG industry. During this time, I collaborated with a
            diverse range of clients, engaged in a wide spectrum of projects, and
            created various business cases to facilitate better decision-making. 
            </p>
            <p>
            I hold an M.Sc. in International Marketing and Management as well as a 
            .Sc. in Economics and Business Administration, both from Copenhagen
            Business School.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> My great enthusiasm for
            being a part of Round's team primarily arises from the opportunity to
            engage within a community that highly values integrity. I appreciate how
            Round challenges conventional paradigms of management consulting by
            incorporating a human perspective into strategy development. Additionally, I am drawn to the startup culture at Round, where I can play an integral role in shaping and influencing the organizational culture.
          </p>
        </Person>
        <Person
          image={images.sebastian}
          name="Sebastian"
          email="sebastian.barfort@round.dk"
          phone="+45 42 46 48 00"
          title="Associate Partner"
        >
          <p>
            I have +5 years of experience from consulting in the public and
            private sector, most recently ReD Associates, where I provided
            strategic guidance to clients on a range of initiatives related to
            innovation, growth, technology, and data-driven decision making. My
            passion for leveraging data and technology to improve the decisions
            we, all of us, make has led to successful outcomes for a diverse
            range of clients, including large technology companies and emerging
            startups.
          </p>
          <p>
            Prior to my consulting career, I was an academic researcher at the
            London School of Economics and University of Copenhagen, where I
            developed and taught courses in social data science. I hold advanced
            degrees in economics from the University of Copenhagen and the
            London School of Economics, as well as an MA in International
            Business and Politics from New York University, where I was a
            Fulbright scholar.
          </p>
          <p>
            <strong>Why am I part of building Round?</strong> I have chosen to
            be a part of building Round because I believe that the world needs a
            consulting company that is designed for the 21st century, one that
            is not only innovative and unconventional, but also values a rich
            and balanced life for its employees, operates sustainably, and gives
            back to society.
          </p>
        </Person>
      </div>
    </Section>
  );
};

const Person = memo(({ image, name, title, email, phone, children, url }) => {
  const [expand, setExpand] = useState(false);
  const els = React.Children.toArray(children);
  const firstPara = els[0];
  const initalText = (
    <p>{firstPara.props.children.substring(0, 300) + '...'}</p>
  );

  return (
    <div className={css.person}>
      <div className={css.image}>
        <GatsbyImage alt={`Profile image of ${name}`} image={image} />
      </div>
      <div className={css.details}>
        <h3>{name}</h3>
        <h4>{title}</h4>
        <div className={css.contact}>
          {url && (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className={css.contactItem}
            >
              <AiFillLinkedin />
            </a>
          )}
          {email && (
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noreferrer"
              className={css.contactItem}
            >
              <AiFillMail />
            </a>
          )}
          {phone && (
            <span className={css.contactItem}>
              <AiFillPhone />
              {phone}
            </span>
          )}
        </div>
        {expand ? children : initalText}
        <button onClick={() => setExpand(!expand)} className={css.readMore}>
          {expand ? 'Close' : 'Read more'}
        </button>
      </div>
    </div>
  );
});

export default memo(WhoWeAre);
